import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

const store = new Vuex.Store({
    
    state:{
        web3:null,
        wallet_address:null
    },
    
    action:{
    },
    
    mutations:{
        setWeb3 (state,payload) {
            // 变更状态
            state.web3 = payload.web3
          },
          setAddress (state,payload) {
            // 变更状态
            state.wallet_address = payload.address
          }
    }
    
    
})
export default store
