<template>
  <div class="header-nav">
    <div class="container">
      <div class="logo">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <div class="right">
        <a href="#about"><div class="text-btn">About</div></a>
        <a href="#roadmap"><div class="text-btn">Roadmap</div></a>
        <a href="#team"><div class="text-btn">Team</div></a>
        <div class="text-btn">Launchpad</div>

        <a href="https://twitter.com/MetaphoXanadu" class="icon"
          ><img src="../assets/img/twitter.svg" alt=""
        /></a>
        <!-- <a href="#" class="icon"
          ><img src="../assets/img/instagram.svg" alt=""
        /></a> -->
        <a href="http://discord.gg/mGdKGDTXKC" class="icon"
          ><img src="../assets/img/discord.svg" alt=""
        /></a>

        <div
          class="connect-wallet"
          @click="connect_wallet"
          v-if="!wallet_address"
        >
          Connect Wallet
        </div>
        <div class="connect-wallet connected" v-else>
          {{user_address}} 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Web3 from "web3";
import config from "@/config"
import { mapState } from 'vuex'
console.log('config',config)
export default {
  data() {
    return {
      chainId:null
    };
  },
  methods: {
    async change_chain() {
      let self = this;
      if (self.chainId != config.targetChain.chainId) {
        window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{chainId:config.chainId}],
          })
          .then((res) => {
              window.location.reload();
          })
          .catch((error) => {
          });
      } 
    },
    async connect_wallet() {
      let self = this;
      let web3Provider;
      // mount 时在全局写入 web3
      if (window.ethereum) {
        web3Provider = window.ethereum;
        try {
          // 请求用户授权
          await window.ethereum.enable();
        } catch (error) {}
      } else if (window.web3) {
        web3Provider = window.web3.currentProvider;
      } 

      web3 = new Web3(web3Provider);
      self.$store.commit('setWeb3',{web3})

      let accounts = await self.web3.eth.getAccounts();
      let chainId = await self.web3.eth.getChainId();


      self.chainId = chainId
      if (accounts.length) {
        let address = accounts[0];
        self.$store.commit('setAddress',{address})
        await self.change_chain()
      }
      window.ethereum.on("chainChanged", (chainId) => {
         self.change_chain()
      });
    },
  },
  computed:{
    user_address(){
      let self = this
      if(self.wallet_address){
        let l = self.wallet_address.length
        return `${self.wallet_address.substring(0,5)}...${self.wallet_address.substring(l-5,l)}`
      }
    },
...mapState(["web3","wallet_address"])
  },
    
  async mounted() {
    let self = this;
    self.connect_wallet();
  },
};
</script>
<style lang="scss">
.header-nav {
  box-sizing: border-box;
  background-color: #292a2c;
  position: fixed;
  z-index: 10;
  width: 100%;
  min-width: 1152px;
  .container {
    width: 1152px;
    margin: 0px auto;
    box-sizing: border-box;

    padding: 10px 0px;
    display: flex;
    align-items: center;
  }
  .logo {
    img {
      width: 200px;
    }
  }
  .right {
    margin-left: auto;
    display: flex;
    align-items: center;
    .icon {
      img {
        width: 35px;
        margin-right: 20px;
      }
    }
    .text-btn {
      margin-right: 20px;
      font-weight: bold;
      font-size: 23px;
      cursor: pointer;
    }
    .connect-wallet {
      background-image: linear-gradient(75deg, #a664a6, #36489c, #8aced0);
      color: white;
      height: 52px;
      line-height: 52px;
      // width: 200px;
      padding: 0px 10px;
      text-align: center;
      border-radius: 5px;
      font-weight: bold;
      margin-left: 20px;
      cursor: pointer;
      border: 1px solid transparent;
      &.connected {
        background-image: none;
        border: 1px solid white;
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 520px) {
  .header-nav {
    width: 100%;
    min-width: unset;
    align-items: center;
    display: flex;
    height: 50px;
    .container {
      padding: 0px 10px;
      box-sizing: border-box;
    }
    .logo {
      img {
        width: 120px;
      }
    }
    .text-btn,
    .icon {
      display: none;
    }
    .right {
      .connect-wallet {
        width: 150px;
        line-height: 40px;
        height: 40px;
      }
    }
    .container {
      width: 100% !important;
    }
  }
}
</style>
