<template>
  <div class="claim-block">
    <!-- <div class="mint-cards">
        <div class="mint-card">
          <div class="title">Price</div>
          <div class="content">0.08 ETH</div>
        </div>
        <div class="mint-card">
          <div class="title">Your Address</div>
          <div class="content">0xb0eE...0xb0eE</div>
        </div>
        <div class="mint-card">
          <div class="title">Time Until Mint</div>
          <div class="content">00:00:00:00</div>
        </div>
      </div> -->
    <div class="select-mint-amount">
      <div class="font">Select Mint Amount:</div>
      <div
        class="block"
        :class="{ selected: mint_amount == 1 }"
        @click="mint_amount = 1"
      >
        1
      </div>
      <div
        class="block"
        :class="{ selected: mint_amount == 2 }"
        @click="mint_amount = 2"
      >
        2
      </div>
      <div
        class="block"
        :class="{ selected: mint_amount == 3 }"
        @click="mint_amount = 3"
      >
        3
      </div>
    </div>
    <div class="mint-btn"  v-if="!wallet_address">Connect Wallet</div>
    <div class="mint-btn" v-else @click="mint">Mint {{mint_amount}} MetaPhox(s) for {{mint_price}} ETH</div>

  </div>
</template>
<script>
import abi from "@/assets/abi.json";
import { mapState } from "vuex";
import config from "@/config"
export default {
  data() {
    return {
      mint_amount: 1,
      price_list:['0.08','0.16','0.24']
    };
  },
computed:{
    ...mapState(["web3", "wallet_address"]),
    mint_price(){
        let self = this
        return self.price_list[self.mint_amount-1]
    }
},
  methods: {
    async mint() {
      let self = this;
      let price = self.mint_price
      let value = self.web3.utils.toWei(price)
      let contract = new self.web3.eth.Contract(abi,config.contractAddress)
       contract.methods.mint(self.mint_amount).send({
          from:self.wallet_address,
          value:value
      }).then(function(e){
      })
    },
  },
};
</script>
